
.detail[data-v-d967aadc] {
    color: #67c23a;
    cursor: pointer;
    margin-top: 10px;
}
.handle-box[data-v-d967aadc] {
    margin-bottom: 20px;
}
.handle-select[data-v-d967aadc] {
    width: 120px;
}
.handle-input[data-v-d967aadc] {
    width: 130px;
    display: inline-block;
}
.table[data-v-d967aadc] {
    width: 100%;
    font-size: 14px;
}
.red[data-v-d967aadc] {
    color: #ff0000;
}
.mr10[data-v-d967aadc] {
    margin-right: 10px;
}
.table-td-thumb[data-v-d967aadc] {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.show[data-v-d967aadc] {
    display: block;
}
.hide[data-v-d967aadc] {
    display: none;
}
.checkbox-rows[data-v-d967aadc] {
    width: 130px;
    margin-top: 10px;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered[data-v-d967aadc] {
    margin-left: 0px;
}
